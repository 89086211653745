<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">To'lov vedimost o'zgartirish</h3>
              </div>
              <div class="card-button">
                <costCategoriesCreate />
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between">
                <div v-if="getDetails.total_elements !== undefined">
                  <div class="Input">
                    <label>Jami</label>
                    <input
                      type="text"
                      disabled
                      class="input-text"
                      :value="
                        getDetails.total_elements.total.toLocaleString('zh-ZH')
                      "
                    />
                  </div>
                </div>
                <div class="Input">
                  <label>Sana</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    :value="getDetails.oper_date"
                  />
                </div>
                <div class="Input" v-if="getDetails.payed_status == false">
                  <label>T'olov status</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    value="Neoplachen"
                  />
                </div>
                <div v-else class="Input">
                  <label>T'olov status</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    value="Oplachen"
                  />
                </div>
                <div class="Input" v-if="getDetails.payment_source == 1">
                  <label>T'olov turi</label>
                  <input type="text" disabled class="input-text" value="Bank" />
                </div>
                <div class="Input" v-if="getDetails.payment_source == 2">
                  <label>T'olov turi</label>
                  <input
                    type="text"
                    disabled
                    class="input-text"
                    value="Kassa"
                  />
                </div>
              </div>
              <div>
                <v-autocomplete
                  v-if="!getDetails.payed_status"
                  v-model="transfer"
                  :items="transfersList"
                  item-value="id"
                  item-text="name"
                  outlined
                  dense
                  return-object
                  label="Vipiska Tranzaksiyalar"
                ></v-autocomplete>
              </div>

              <v-data-table
                v-if="!loading"
                :headers="headers"
                :loading="isLoading"
                loading-text="...."
                :items="getDetails.paymentstatementelements_set"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:[`item.index`]="{ item }">
                  {{
                    getDetails.paymentstatementelements_set
                      .map(function (x) {
                        return x.id
                      })
                      .indexOf(item.id) + 1
                  }}
                </template>

                <template v-slot:[`item.amount`]="{ item }">
                  <input
                    type="text"
                    v-model="item.amount"
                    v-currency="options"
                  />
                </template>
              </v-data-table>
              <div class="mt-10">
                <!-- {{ parseInt(getTotal) + parseInt(this.summ) }} -->
                <div class="d-flex total__results">
                  <div>
                    <p>Umumiy Summa - {{ this.summ | mask }}</p>
                  </div>
                  <div
                    class="mx-2"
                    :class="this.summ - transfer.amount <= 0 ? 'minus' : 'plus'"
                  >
                    <p>Farqi - ({{ (this.summ - transfer.amount) | mask }})</p>
                  </div>
                  <div>
                    <p>Tranzaksiya Summasi - {{ transfer.amount | mask }}</p>
                  </div>
                </div>
              </div>
              <div class="mt-5">
                <v-btn color="success" @click="sendDdata">Saqlash</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Swal from 'sweetalert2'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  data() {
    return {
      paymentstatement_elements: [],
      transfer: '',
      summ: 0,
      headers: [
        { text: '#', value: 'index' },
        { text: 'Hodim', value: 'staffemployed_name' },
        { text: 'Tabel raqam', value: 'tabel_number' },
        { text: 'Summa', value: 'amount' }
      ],
      selectedCurrencyOption: 1,
      currencyCode: 'USD',
      distractionFree: false,
      hideCurrencySymbol: true,
      hideGroupingSymbol: true,
      hideNegligibleDecimalDigits: true,
      prefix: null,
      suffix: null,
      precisionEnabled: true,
      precisionRangeEnabled: false,
      precisionFixed: 0,
      precisionRange: [0, 20],
      valueRangeEnabled: false,
      valueRange: [0, 9999]
    }
  },
  created() {
    this.$store.dispatch(
      'getDetailsSalaryPaymentStatements',
      this.$route.params.id
    )
  },
  watch: {
    getDetails: {
      deep: true,
      handler(val) {
        // console.log(val.paymentstatementelements_set.map(x => x.amount))
        this.summ = val.paymentstatementelements_set
          .map((x) => x.amount)
          .reduce((a, b) => {
            const total = this.parseFloat(a) + this.parseFloat(b)
            return total
          }, 0)
      }
    }
  },
  filters: {
    mask(val) {
      // eslint-disable-next-line no-extra-boolean-cast
      if (Boolean(val)) return val.toLocaleString('zh-ZH')
      else return 0
    }
  },
  computed: {
    transfersList() {
      const list = this.$store.state.requests.transferSalaryList
      list.forEach((x) => {
        x.name =
          x.type_statetransfer_name +
          ' summa: ' +
          x.amount.toLocaleString('zh-ZH') +
          ' | ' +
          x.comment
      })
      return list
    },
    getTotal() {
      const list = this.$store.state.requests.detailsSalaryPaymentStatements
      if (list.total_elements !== undefined) return list.total_elements.total
      else return list
    },
    getDetails() {
      const data = this.$store.state.requests.detailsSalaryPaymentStatements
      if (data.payment_source !== undefined) {
        this.$store.dispatch('getTransferSalaryList', {
          type_transfer: 'salary',
          payment_source: data.payment_source
        })
      }
      return data
    },
    options() {
      return {
        locale: 'zh-ZH',
        currency: [
          this.currencyCode,
          null,
          { prefix: this.prefix, suffix: this.suffix }
        ][this.selectedCurrencyOption],
        valueRange: this.valueRangeEnabled
          ? { min: this.valueRange[0], max: this.valueRange[1] }
          : undefined,
        precision: this.precisionEnabled
          ? this.precisionRangeEnabled
            ? { min: this.precisionRange[0], max: this.precisionRange[1] }
            : this.precisionFixed
          : undefined,
        distractionFree: this.distractionFree
          ? {
              hideNegligibleDecimalDigits: this.hideNegligibleDecimalDigits,
              hideCurrencySymbol: this.hideCurrencySymbol,
              hideGroupingSymbol: this.hideGroupingSymbol
            }
          : false,
        autoDecimalMode: this.autoDecimalMode,
        valueAsInteger: this.valueAsInteger,
        allowNegative: this.allowNegative
      }
    }
  },
  methods: {
    parseFloat(val) {
      if (typeof val == 'string' && Boolean(val)) {
        // console.log(parseFloat(val.replace(/[ ,]/g, ''), 10))/
        return parseFloat(val.replace(/[ ,]/g, ''), 10)
      } else if (typeof val == 'number') return val
      else return 0
    },
    sendDdata() {
      // if (this.transfer.amount !== undefined) {
      const collData = this.getDetails.paymentstatementelements_set.map((x) => {
        const data = {
          id: x.id,
          amount: this.parseFloat(x.amount)
        }
        return data
      })
      const data = {
        paymentstatement_elements: [...collData],
        paymentstatement: {
          id: parseInt(this.$route.params.id, 10),
          transaction: this.transfer
        }
      }
      if (this.transfer.amount !== undefined) {
        if (this.summ !== this.transfer.amount) {
          delete data.paymentstatement.transaction
          // this.$store.dispatch('updatePaymentStatemnts')
        } else {
          data.paymentstatement.transaction =
            data.paymentstatement.transaction.id
        }
      } else {
        delete data.paymentstatement.transaction
      }
      console.log(data)
      this.$store.dispatch('updatePaymentStatemnts', data)
      // }
      // }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Ish haqi hisobotlari' },
      { title: "To'lov vedimost batafsil" }
    ])
  }
}
</script>

<style scoped>
.Input label {
  display: block;
  font-size: 12px;
}
.Input {
  margin-right: 6px;
  margin-bottom: 30px;
}
.Input:last-child {
  margin-right: 0;
}
.input-text {
  background-color: rgb(142 190 253 / 14%);
  padding: 0.2rem 0.5rem;
  border: 1px solid rgb(142 190 253 / 28%);
  color: inherit;
  /* color: rgb(24, 24, 24); */
  /* box-shadow: 0px 5px 7px 3px rgba(222, 222, 222, 0.66); */

  font-size: 16px;
  border-radius: 3px;
}
.col-4,
.col-12 {
  padding-left: 0;
}
.input__wrapper {
  display: flex;
  flex-wrap: wrap;
}
.total__results div {
  border-radius: 4px;
  color: #000;
  padding: 10px;
  flex: 1;
}
.total__results div:nth-child(1) {
  background-color: rgb(134, 220, 126);
}
/* .total__results div:nth-child(2) {
  background-color: rgb(237, 217, 103);
} */
.minus {
  background-color: rgb(245, 152, 152);
  transition: all 0.2s;
}
.plus {
  background-color: rgb(237, 217, 103);
  transition: all 0.2s;
}
.total__results div:nth-child(3) {
  background-color: rgb(158, 191, 247);
}
.total__results div p {
  margin: 0;
}
</style>
